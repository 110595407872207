/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { D, Q } from '@angular/cdk/keycodes';
import { getAllInfo, getFullItemTwo, NodePoint_0_0_2 } from '@flexus/core';
import { KVLHeading} from '@flexus/ui-elements';
import { toInteger } from '@flexus/utilities';
import gql from 'graphql-tag';
import { forkJoin, take, map, filter } from 'rxjs';

export const jobInfoNode = (state?: string | number, heading?: string, instructions?: any[]): NodePoint_0_0_2 => {
	const pre_warranty_states = [229, 236, 243, 258, 259, 263, 265, 266, 273, 274, 284];
	return {
		name: 'Job Information',
		serverCalls: {
			important_information: {
				errorMessage: `Could not get important information`,
				directCall: (_http: any, _store: any) => {
					return _store
						.select(getFullItemTwo)
						.pipe(
							filter(Boolean),
							take(1),
							)
						.pipe(
							map((selectedJob: any) => {
								const { job_information } = selectedJob;
								const list: any[] = [];
								if (state === 273) {
									const incentive_reason: string = job_information?.installer_incentive_reason ?? 'No reason provided';
									const incentive_amount: number = job_information?.installer_incentive_amount ?? 'No incentive';
									const incentive_comment: string = job_information?.installer_incentive_comments ?? 'No comments';

									const importantObj = {
										'Reason for Incentive ': `${incentive_reason}`,
										'Additional amount ': `${incentive_amount}`,
										'Additional comments': `${incentive_comment}`
									};
									list.push(importantObj);
									return list;
								} else if (state === 274) {
									const additional_reason: string = job_information?.additional_fees_reason ?? 'No reason provided';
									const additional_amount: string = job_information?.additional_fees_amount ?? 'No additional fees';
									const current_incenctive: string = job_information?.installer_incentive_amount ?? 'No existing incentive';

									const importantObj = {
										'Additional amount requested ': `${additional_amount}`,
										'Reason for request ': `${additional_reason}`,
										'Incentive value': `${current_incenctive}`
									};
									list.push(importantObj);
									return list;
								} 
								else if (state === 382) {
									const {builders_sp_assistance} = job_information;
									const importantObj = {
										'Assistance required from SP ': builders_sp_assistance ? `${builders_sp_assistance}` : 'No description found',
										
									};
									list.push(importantObj);
									return list;
								} 
								else {
									return null;
								}
							})
						);
				}
			},
			voucher_information: {
				errorMessage: 'could not get the pinggo installation summary',
				directCall: (http: any, store: any, sq: any) => {
					return forkJoin([
						sq.queryObject(
							gql`
								{
									fullItemTwo {
										claim {
											loan_information {
												voucher_key
												voucher_type
											}
											source
										}
									}
								}
							`,
							store.select(getFullItemTwo).pipe(
								filter(Boolean),
								take(1),
								map((res: any) => ({ fullItemTwo: res }))
							)
						),
						store.select(getAllInfo).pipe(
							filter(Boolean),
							take(1),
							)
					])
					.pipe(
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						map(([queryData, info]: any) => {
							// const { estimate_installation_times }: any = estimates;
							// const skillid: number = +queryData?.skill_id;
							// const estimate: any = estimate_installation_times.find(el => el.skill === skillid).installation_time;
							const voucherkey: string = queryData?.voucher_key;
							const voucherName: string = queryData?.voucher_type;
							const voucherclient: string = queryData?.source;
							const quantity: string = queryData?.quantity ? queryData?.quantity : 1;

							const list: any[] = [];
							const installationinfo = {
								'Voucher Number': `${voucherkey}`,
								'Voucher Name': `${voucherName}`,
								'Voucher Client': `${voucherclient}`,
								'Quantity (If Applicable)': `${quantity}`
							};
							list.push(installationinfo);
							return list;
							// return { fullItemTwo: queryData };
						})
					);
				}
			},
			customer_details: {
				errorMessage: `Could not get important information`,
				directCall: (http: any, store: any, sq: any) => {
					return sq
						.queryObject(
							gql`
								{
									fullItemTwo {
										address
										claim {
											applicant {
												first_name
												contact_number
											}
										}
									}
								}
							`,
							store.select(getFullItemTwo).pipe(
								filter(Boolean),
								take(1),
								map((res: any) => ({ fullItemTwo: res }))
							)
						)
						.pipe(
							map((querydata: any) => {
								const name: string = querydata?.first_name;
								const number: string = querydata?.contact_number;
								const address: string = querydata?.address;
								const list: any[] = [];
								const contactinfo = {
									'Customer name': `${name}`,
									'Customer Contact Number': `${number}`,
									Address: `${address}`
								};
								list.push(contactinfo);
								return list;
							})
						);
				}
			},
			installation_summary: {
				errorMessage: 'could not get the pinggo installation summary 1',
				directCall: (http: any, store: any, sq: any) => {
					return forkJoin([
						sq.queryObject(
							gql`
								{
									fullItemTwo {
										claim {
											loan_information {
												voucher_key
												skill_id
												voucher_type
											}
										}
									}
								}
							`,
							store.select(getFullItemTwo).pipe(
								filter(Boolean),
								take(1),
								map((res: any) => ({ fullItemTwo: res }))
							)
						),
						store.select(getAllInfo).pipe(
							filter(Boolean),
							take(1)
						)
					]).pipe(
						map(([queryData, info]: any) => {
							const list: any[] = [];
							if(typeof queryData === 'object' && Object.keys(queryData).length > 0) {	
								if (state === 27	) {
									const { estimate_installation_times, skills }: any = info;
									// const skillid: number = +queryData?.skill_id;
									// const estimate: any = estimate_installation_times.find(el => el.skill === skillid).installation_time;
									const voucherkey: string = queryData?.voucher_key;
									const product: string = queryData?.voucher_type;
									// const skillstring = skills.find(el => el.id === skillid).name;
									const installationinfo = {
										'Voucher Number': `${voucherkey}`,
										Product: `${product}`,
										// 'Estimated time to complete installation': `${estimate} hrs`
										// 'Product comments': `${comments}`
									};
									list.push(installationinfo);
									return list;
									} else {
									const { estimate_installation_times, skills }: any = info;
									const skillid: number = +queryData?.skill_id;
									const estimate: any = estimate_installation_times.find(el => el.skill === skillid).installation_time;
									const voucherkey: string = queryData?.voucher_key;
									const product: string = queryData?.voucher_type;
									// const skillstring = skills.find(el => el.id === skillid).name;
									const installationinfo = {
										'Voucher Number': `${voucherkey}`,
										Product: `${product}`,
										'Estimated time to complete installation': `${estimate} hrs`
										// 'Product comments': `${comments}`
									};
									list.push(installationinfo);
									return list;
									}
							} else if (typeof queryData === 'object' && Object.keys(queryData).length === 0) {
								store.select(getFullItemTwo).pipe(
								filter(Boolean),
								take(1),
								map((res: any) => {
									const { job_information, skill } = res;
									const { voucher } = job_information;
									const voucher_code = voucher?.voucher_code;
									const { estimate_installation_times, skills }: any = info;
									const skillid: number = skill
									const estimate: any = estimate_installation_times.find(el => el.skill === skillid).installation_time ? estimate_installation_times.find(el => el.skill === skillid).installation_time : 'No estimate in the configuration';
									// const skillstring = skills.find(el => el.id === skillid).name;
									const installationinfo = {
										'Voucher Number': `${voucher_code}`,
										'Estimated time to complete installation': `${estimate}`,
										// 'Product comments': `${comments}`
									};
									list.push(installationinfo);
								})
							).subscribe();
							}
							return list
						})
					);
				}
			},
			installer_details: {
				errorMessage: 'could not get the pinggo installer details',
				directCall: (http: any, store: any) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							filter(Boolean),
							take(1),
							)
						.pipe(
							map((leader: any) => {
								let installername: string = '';
								let installernumber: string = '';
								let installer_onsite: string = '';
								let installer_complete: string = '';

								if (leader?.team_leader === null) {
									installername = 'no team leader detail';
									installernumber = 'no contact number provided';
								} else {
									installername = leader?.team_leader?.full_name;
									installernumber = leader?.team_leader.contact_number;
								}
								if (state === 273 || state === 21) {
									installer_onsite = 'No';
									installer_complete = 'No';
								} else {
									installer_onsite = 'Yes';
									installer_complete = 'Yes';
								}
								const installerobj = {
									'Installer name': `${installername}`,
									'Installer Contact Number': `${installernumber}`,
									'Installer was on site': `${installer_onsite}`,
									'Installer completed installation': `${installer_complete}`
								};
								const list: any[] = [];
								list.push(installerobj);
								if (state === 263) {
									return null;
								} else {
									return list;
								}
							})
						);
				}
			},
			callout_fee_detail: {
				errorMessage: 'could not get the pinggo callout fee details',
				directCall: (http: any, store: any) => {
					return store
						.select(getFullItemTwo)
						.pipe(
							filter(Boolean),
							take(1))
						.pipe(
							map((job: any) => {
								const { job_information } = job;
								const { warranty_job_decision, manufacturer_aid, manufacturer_sp_assistance } = job_information;
								const list: any[] = [];
								let infotext = '';
								switch(warranty_job_decision) {
									case 'NO_ADDITIONAL_WORK_REQUIRED':
										infotext = '4-Sure absorbs fee';
										break;
									case 'CUSTOMER_TO_DEAL_WITH_MANUFACTURER':
										infotext = 'Customer to pay callout fee';	
										break;
									case 'BUILDERS_REQUIRES_ASSISTANCE':
										infotext = 'Builders to pay callout fee';
										break;
									case 'MANUFACTURER_REQUIRES_ASSISTANCE':
										infotext = 'Manufacturer to pay callout fee';
										break;
									default:
										infotext = 'No creditor found';
								}
								if(manufacturer_aid) {
									const return_obj = {
										'Responsible for call': `${infotext}`,
										'Corresponding contact person': `${manufacturer_sp_assistance?.manuf_contact_person}`,
										'Corresponding contact number': `${manufacturer_sp_assistance?.manuf_contact_person_phone}`,	
										'Fee Anount': 'R650'
									}
									list.push(return_obj);
									// return list
								} else {
									const return_obj = {
										'Responsible for call': `${infotext}`,	
									}
									list.push(return_obj);
								}
								return list
							})
						);
				}
			}
		},
		component: {
			children: [
				...(pre_warranty_states.includes((toInteger(state))) ? [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: heading,
							instructions: instructions
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							color: 'secondary',
							data$: 'important_information',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Customer details', 'secondary'),
							data$: 'customer_details',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Voucher Information', 'secondary'),
							data$: 'voucher_information',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Installation Summary', 'secondary'),
							data$: 'installation_summary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Installer Details', 'secondary'),
							data$: 'installer_details',
							itemMargin: '0 0 35px 0'
						}
					}
				]: [] ),
				...(state === 376 ? [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Confirm cost have been recouped'
						}	
					},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Follow up with the party that was marked as responsible for recouping the cost',
							headingConfig: {
								size: 'small',
								color: 'secondary',
								weight: 'normal',
								itemMargin: '-10px 0 10px 0'
							}
						}	
					},
					
					// NOTE: Display creditor name
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							data$: 'important_information',
							color: 'secondary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',	
							data$: 'customer_details',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Summary',
							data$: 'installation_summary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Callout Fee Detail',
							data$: 'callout_fee_detail',
							headingConfig: {
								itemMargin: '10px 0 0 0'
							}
						}
					},

				] 
				: 
				[]),
				...(state === 373
					? [
							{
								component: 'FLXHeadingWithInstructionsComponent',
								inputs: {
									title: 'Product Warranty'
								}
							},
							{
								component: 'FLXHeadingWithInstructionsComponent',
								inputs: {
									title: 'Please indicate if the supplier or manufacturer needs additional assistance from us',
									headingConfig: {
										size: 'small',
										weight: 'default',
										color: 'secondary',	
										itemMargin: '-10px 0 35px 0'
									}
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									colouredHeading: new KVLHeading('Customer Details', 'secondary'),
									data$: 'customer_details'
								}
							},
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									colouredHeading: new KVLHeading('Voucher Information', 'secondary'),
									data$: 'voucher_information',
									itemMargin: '0 0 35px 0'
								}
							},
							// {
							// 	component: 'FLXKeyValueListComponent',
							// 	inputs: {
							// 		colouredHeading: new KVLHeading('Installation Summary', 'secondary'),
							// 		data$: 'installation_summary',
							// 		itemMargin: '0 0 35px 0'
							// 	}
							// },
							{
								component: 'FLXKeyValueListComponent',
								inputs: {
									colouredHeading: new KVLHeading('Installer Details', 'secondary'),
									data$: 'installer_details',
									itemMargin: '0 0 35px 0'
								}
							}
					  ]
					: []), 
				...(state === 375 ? [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Who do we recover the costs from'
						}	
					},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Please indicate where the costs for SP call out need to be recovered from',
							headingConfig: {
								size: 'small',
								color: 'secondary',
								weight: 'normal',
								itemMargin: '-10px 0 35px 0'
							}
						}	
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Customer Details', 'secondary'),
							data$: 'customer_details'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Installation Summary', 'secondary'),
							data$: 'installation_summary'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Callout Fee Detail', 'secondary'),
							data$: 'callout_fee_detail'
						}
					},

				] 
				: 
				[]),
				...(state === 382 ? [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary'
						}	
					},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Service provider has completed the requested warranty work',
							headingConfig: {
								size: 'small',
								color: 'secondary',
								weight: 'normal',
								itemMargin: '-10px 0 10px 0'
							}
						}	
					},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Contact the customer and Manufacturer/Builders to confirm that everyone is happy with the work',
							headingConfig: {
								size: 'small',
								color: 'secondary',
								weight: 'normal',
								itemMargin: '-10px 0 35px 0'
							}
						}	
					},
					
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							data$: 'important_information',
							color: 'secondary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',	
							data$: 'customer_details',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Summary',
							data$: 'installation_summary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Callout Fee Detail',
							data$: 'callout_fee_detail',
							headingConfig: {
								itemMargin: '10px 0 0 0'
							}
						}
					},

				] 
				: 
				[]),
				
				...(state === 27 ? [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Information',
							headingConfig: {
								size: 'default',
								color: 'default',
								weight: 'normal',
								itemMargin: '35px 0 10px 0'
							}
						}	
					},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Please check the photos on the job to confirm they are correct',
							headingConfig: {
								size: 'small',
								color: 'secondary',
								weight: 'normal',
								itemMargin: '-10px 0 10px 0'
							}
						}	
					},
					
					
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							data$: 'important_information',
							color: 'secondary',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Voucher Information', 
							data$: 'voucher_information',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',	
							data$: 'customer_details',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Installation Summary',
							data$: 'installation_summary',
							itemMargin: '0 0 35px 0'
						}
					},
					// {
					// 	component: 'FLXKeyValueListComponent',
					// 	inputs: {
					// 		heading: 'Callout Fee Detail',
					// 		data$: 'callout_fee_detail',
					// 		headingConfig: {
					// 			itemMargin: '10px 0 0 0'
					// 		}
					// 	}
					// },
				] : [])
				
			]
		}
	};
};
