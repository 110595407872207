/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getData, getFullItemOne, getCurrentUser, SetNextNode, getSelectedItem, BigFormService } from '@flexus/core';
import {  UntypedFormControl, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import {  getFullItemTwo, getAllInfo } from '@flexus/core';
import { forkJoin, of, EMPTY, map, take, filter, pluck, switchMap } from 'rxjs';
import gql from 'graphql-tag';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { photoNode } from '../reusable/PHOTO_NODE';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { convertDateTimeToTimeStamp } from '@flexus/utilities';


export const PGG_27: Flow_0_0_2 = {
	id: '27',
	name: 'job_invoiced',
	itemType: 'flow',
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				filter(Boolean),
				take(1),
				map((itemOne: any) => {
					let headerStr = 'Job Invoiced';
					if (itemOne) {
						headerStr += ` : ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headerStr;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Approve SP invoice'
		},
		viewRoles: {
			0: 'Wait for SP invoice approval'
		}
	},

	events: {
		invalidateQueryDataIfJobTotalIsSet: {
			triggerOn: 'claim_value',
			triggerWhen: (rate: any) => !!rate,
			dataMutations: (bf: any) => {
				bf.patchValues({
					query: null,
					new_state: 226
				});
			}
		},

		invalidatePaymentDataIfQueryIsSet: {
			triggerOn: 'query',
			triggerWhen: (qr: any) => !!qr,
			dataMutations: (bf: any) => {
				bf.patchValues({
					claim_value: null,
					new_state: 46
				});
			}
		}
	},
	actionPanel: (instance: any) => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	fetchLevel1And2: true,
	serverCalls: {
		invoice: {
			serviceVariable: 'pggService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},
		dataFiles: {
			errorMessage: 'No invoice found!',
			directCall: (http: any, store: any, sq: any) => {
				// return throwError('something went wrong');
				return sq
					.queryObject(
						gql`
							{
								invoice {
									data
								}
							}
						`,
						store.select(getData)
					)
					.pipe(
						filter((x: any) => !!x && Object.keys(x)?.length !== 0),
						take(1),
						map((data: any) => data['data'])
					);
			}
		},
		files: {
			serviceVariable: 'pggService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		notes: {
			serviceVariable: 'pggService',
			functionName: 'getNotes',
			responseSlice: 'payload',
			errorMessage: 'No claim notes could be found!'
		}
	},

	startNode: 'JobInformation',

	nodes: {
		JobInformation: {
			showTabs: true,
			name: 'Job Information',
			...jobInfoNode(27),
			navs: [
				{
					text: 'Continue',
					nextNode: 'Photos',
				}
			]
		},

		Photos: {
			initFormFields: (bf: any) => {
				bf.patchValues({ new_state: 27 });
			},
			...photoNode(27),
		},
		PaymentPreview: {
			showTabs: true,
			name:'Invoice',
			inputs: { inputHeading: 'Payment Preview' },
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: { data$: 'keyValueList' }
					}
				]
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http: any, store: any, sq: any) => {
						const invoice$ = sq
							.queryStore(
								gql`
									{
										selectedContext {
											invoice
										}
									}
								`
							)
							.pipe(
								filter((x: any) => !!x && Object.keys(x)?.length !== 0),
								take(1)
							);
						return forkJoin([
							store.select(getFullItemOne).pipe(
								filter(Boolean),
								take(1)
							),
							store.select(getFullItemTwo).pipe(
								filter(Boolean),
								take(1)
							),
							store.select(getAllInfo).pipe(
								filter(Boolean),
								take(1)
							)
						]).pipe(
							take(1),
							map(([claim, job, allInfo]: any) => {
								const list = {
									Name: claim?.applicant ? `${claim?.applicant?.first_name}` : '',
									Voucher: claim?.loan_information?.voucher_key ?? '',
									Address: job?.address ?? 'No address found',

									'Amount due': job?.claim_value ?? 0,
									// 'Amount due': invoice && invoice.balance_due ? `R ${invoice.balance_due}` : 0,
									// 'Invoice number': invoice && invoice.invoice_number ? invoice.invoice_number : '',
									'Job Status': ((): string => {
										let job_status = '';
										if (allInfo) {
											allInfo['valid_job']?.forEach(element => {
												if (element && element.id === job?.valid_job) {
													job_status = element.name;
												}
											});
										}
										return job_status;
									})()
								};
								let cloned = JSON.parse(JSON.stringify(list));
								if (job?.supplier_type === 2) {
									const { 'Amount due': deleted, ...rest } = list;
									cloned = rest;
								}

								return [cloned];
							})
						);
					}
				}
			},
			initFormFields: (bf: any, item: any, instance: any, sq: any, store: any) => {
				forkJoin([
					store.select(getFullItemOne).pipe(
						filter(Boolean),
						take(1)
					),
					store.select(getCurrentUser).pipe(
						filter(Boolean),
						take(1)
					)
				])
					.pipe(take(1))
					.subscribe(([claim, user]: any) => {
						bf.patchValues({
							paymentapprovalhandler: claim?.application_creator?.id ?? '',
							paymentapproval1: user?.user?.id ?? ''
						});
					});
			},
			navs: [
				
				{
					text: 'Approve Photos',
					color: 'primary',
					nextNode: 'Photos'
				}
			]
		},
		PaymentConfirm: {
			showTabs: false,
			hideTabItem: true,
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'claim_value',
									inputType: 'currency',
									label: 'Invoice amount'
								}
							},
							formLayout: 'three-column',
							containerWidth: '50vw'
						}
					}
				]
			},

			initFormFields: (bf: any, item: any, instance: any, sq: any) => {
				bf.patchValues({ new_state: 226 });
				bf.bigForm.get('claim_value')?.setValidators([Validators.required]);
				bf.bigForm.get('invoice_number')?.setValidators([Validators.required]);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
									supplier_type
								}
							}
						}
					`
				)
					.pipe(
						filter((x: any) => !!x && Object.keys(x)?.length !== 0),
						take(1)
					)
					.subscribe((values: any) => {
						// If cash in lue job vat rate = 0
						const vatRate = values?.supplier_type === 2 ? 0 : 15;
						bf.patchValues({
							current_state: values.state,
							vat_rate: vatRate
						});
					});
			},

			checkValidityForFields: ['claim_value'],

			inputs: {
				inputHeading: 'Payment Confirmation'
			},

			navs: [
				{
					text: 'Approve',
					color: 'primary',
					linkType: 'portal',
					optIntoValidation: true,
					portalData: {
						type: 'modal',
						paramFunc: (instance: any, store: any, bf: any) => {
							instance.showCalculations([{ display: 'Invoice amount', value: bf.bigForm?.value?.claim_value, type: 'add' }], 'Amount to pay');
							instance.type = 'info';
							instance.navButtons = [
								{
									text: 'Cancel',
									color: 'alert',
									linkType: 'close'
								},
								{
									text: 'Continue',
									color: 'primary',
									linkType: 'submitThenNext',
									serverCalls: {
										response: {
											directCall: (http: any, store: any, sq: any, bf: any) => {
												return store
													.select(getSelectedItem)
													.pipe(
														filter(Boolean),
														take(1)).pipe(
														switchMap((res: any) => {
															const data = {
																job_id: res?.id,
																new_state: bf.bigForm.get('new_state')?.value
															};
															return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
														})
													);
											},
											errorMessage: `Couldn't update job`
										}
									},
									nextNode: 'SubmissionSuccess'
								}
							];
						}
					}
				}
			]
		},
		ReturnToSP: {
			checkValidityForFields: ['return_tl_reason'],
			showTabs: false,
			hideTabItem: true,
			initFormFields: (_bf: BigFormService) => {
			  _bf.addControl('return_tl_reason', new UntypedFormControl('', [Validators.required]));
			  _bf.patchValues({ new_state: 298 });
			},
			component: {
			  children: [
				{
				  component: 'FLXFlatDynamicFormComponent',
				  inputs: {
					heading: 'Reason for sending this back to the SP',
					formControls: {
					  0: {
						label: 'Please enter the reason for returning this to the team leader',
						inputType: 'textarea',
						rows: 5,
						formControlName: 'return_tl_reason'
					  }
					},
					formLayout: 'stacked',
					containerWidth: '50vw',
					headingSize: 'medium',
					headingWeight: 'light',
					headingType: 'creation',
					headingMargin: '20px 0 25px 0'
				  }
				}
			  ]
			},
			navs: [
			  {
				text: 'submit',
				optIntoValidation: true,
				// linkType: 'portal',
				serverCalls: {
					response: {
						errorMessage: 'Job could not be updated',
						serviceVariable: 'pggService',
						functionName: 'updateJob'
					}
				},
				nextNode: 'SubmissionSuccess',
				color: 'primary'
			  }
			]
		  },

		QueryInvoice: {
			hideTabItem: true,
			showTabs: false,
			component: {
				children: [
					{
						component: 'FLXFileViewerComponent',
						inputs: { base64Source$: 'dataFiles', mimeType: 'pdf' }
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'query',
									inputType: 'textarea',
									label: 'Query Invoice',
									maxWidth: '50vw'
								}
							}
						}
					}
				]
			},

			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},

			inputs: {
				inputHeading: 'Query Invoice'
			},

			checkValidityForFields: ['query'],

			initFormFields: (bf: any, item: any, instance: any, sq: any) => {
				bf.patchValues({ query: '', new_state: 46 });
				bf.bigForm.get('query')?.setValidators([Validators.required, Validators.minLength(3)]);
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
								}
							}
						}
					`
				)
					.pipe(
						filter((x: any) => !!x && Object.keys(x)?.length !== 0),
						take(1)
					)
					.subscribe((values: any) => {
						bf.patchValues({ current_state: values.state });
						// bf.addControl('current_state', new FormControl(values.state));
					});
			},

			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					linkType: 'submit',
					color: 'primary',
					serverCalls: {
						response: {
							errorMessage: 'Job could not be updated',
							serviceVariable: 'pggService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},

	bigFormToStoreMapper: {
		current_state: 'current_state',
		new_state: 'new_state',  
		claim_value: ['claim_value', 'job_information.firsteyes_claim_value'],
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		return_tl_reason: [
			(_reason: string, _storeobj: any) => {
			  const return_tl_reasons = _storeobj['selectedContext']?.fullItemTwo?.job_information?.return_tl_reasons;
			  if (_reason) {
				const return_reasons = [];
				const date = new Date();
				const new_reason = {
				  reason: _reason,
				  author_name: _storeobj?.identity?.currentUser?.user?.full_name,
				  time_stamp: convertDateTimeToTimeStamp(date.toDateString())
				};
				if (return_tl_reasons) {
				  Array.isArray(return_tl_reasons) ? return_reasons?.push(...return_tl_reasons, new_reason) : return_reasons?.push(return_tl_reasons, new_reason);
				} else {
				  return_reasons.push(new_reason);
				}
				return return_reasons;
			  } else {
				return return_tl_reasons;
			  }
			},
			'job_information.return_tl_reasons'
		  ],
		query: [
			(qr: any, storeObj: any) => {
				let iaq = [];
				if (
					storeObj &&
					storeObj['selectedContext'] &&
					storeObj['selectedContext']?.fullItemTwo &&
					storeObj['selectedContext']?.fullItemTwo.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery)) {
						iaq = storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery;
					} else {
						iaq = [
							{
								...storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery
							}
						];
					}
				}
				const invoicequery = [...iaq, { query: qr, queryreply: '' }];
				return invoicequery;
			},
			'job_information.invoicequery'
		]
	}
};
  