/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigFormService, Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { setActionPanelItems } from '../../../../app-shell-features';
import { skipWhile, take, map} from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UntypedFormControl, Validators } from '@angular/forms';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';

export const PGG_375: Flow_0_0_2 = {
	id: '375',
	name: 'hardware_warranty_resolved',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Hardware Warranty Resolved';
					if (itemOne?.loan_information?.voucher_key) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Warranty Outcome'
		},
		viewRoles: {
			0: 'Warranty Outcome'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'pggService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		
	},
	startNode: 'jobInformation',
	nodes: {
		jobInformation: {
            initFormFields: (_bf: BigFormService) => {
                _bf.addControl('new_state', new UntypedFormControl( 28, [Validators.required]));
            },
			showTabs: false,
			...jobInfoNode(375),
           
            navs: [
                { 
                    text: '4-sure absorbs fee',
                    color: 'primary',
                    nextNode: 'submissionSuccess',
                    serverCalls: {
                       response: {
                        errorMessage: 'Could not update job',
                        serviceVariable: 'pggService',
                        functionName: 'updateJob'
                       }
                    }
                },
                { 
                    text: 'manufacturer to pay callout fee',
                    color: 'primary',
                    // linkType: 'submit',
                    nextNode: 'submissionSuccess',
                    serverCalls: {
                        response: {
                         errorMessage: 'Could not update job',
                         serviceVariable: 'pggService',
                         functionName: 'updateJob'
                        }
                     }
                },
                { 
                    text: 'builders to pay callout fee',
                    color: 'primary',
                    nextNode: 'submissionSuccess',
                    serverCalls: {
                        response: {
                         errorMessage: 'Could not update job',
                         serviceVariable: 'pggService',
                         functionName: 'updateJob'
                        }
                     }
                  
                },
                { 
                    text: 'customer to pay callout fee',
                    color: 'primary',
                    nextNode: 'submissionSuccess',
                    serverCalls: {
                        response: {
                         errorMessage: 'Could not update job',
                         serviceVariable: 'pggService',
                         functionName: 'updateJob'
                        }
                     }
                },
                
            ]
		},
       
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
	}
};
