/* eslint-disable @typescript-eslint/no-explicit-any */
import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BigFormService, getFullItemTwo, getSelectedItem } from '@flexus/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { skipWhile, map, take } from 'rxjs/operators';

@Component({
	selector: 'flx-pinggo-sp-payment-breakdown',
	templateUrl: 'pinggo-sp-payment-breakdown.component.html',
	styleUrls: ['pinggo-sp-payment-breakdown.component.scss']
})
export class PinggoSPPaymentBreakdownComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() paymentVariables$!: Observable<any>;
	// @Input() componentstate: number;
	subheading;
	// subheading = `Edit or waive the 'Installer Additional' amount to update the various payments due on the job.`;
	refundCalcFormGroup!: UntypedFormGroup;
	netresult!: any;
	customer_installerfee_deduction!: any;
	customer_installerincentive_deduction!: any;
	customer_installeradditional_deduction!: any;
	refund_amount_due!: any;
	installerTotal: any;
	installer_incentive: any;
	installer_additional: any;
	voucheramount!: number;
	client_value!: number;
	sp_value!: any;

	state: any;
	stateSub: Subscription;
	valueSub!: Subscription;
	incentiveAdditionalSub!: Subscription;
	paymentBreakdownSubscription!: Subscription;
	amountsControl = this.bf.getControl('amounts');
	stateSubscription!: Subscription;

	constructor(public bf: BigFormService, private cdr: ChangeDetectorRef, private _store: Store<any>) {
		this.stateSub = this._store
			.select(getSelectedItem)
			.pipe(
				skipWhile((selected: any) => !selected),
				map((item: any) => {
					this.state = item?.state;
				})
			)
			.subscribe();
	}

	ngOnInit() {
		console.log('bigform', typeof this.bf.bigForm.controls['amounts']?.value.cus_installer_additional);
		this.subheading = this.state === 273 ? `Approve, Edit or Decline the Requested Installer Incentive.` : `Approve, Edit or Decline the Requested Installer Additional.`;

		this.paymentBreakdownSubscription = this.paymentVariables$
			.pipe(
				skipWhile(x => !x),
				take(1),
				map((data: any) => {
					this.voucheramount = +data?.refundVariableObj?.voucher_amount;
					this.client_value = +data?.refundVariableObj?.client_value;
					this.sp_value = data?.refundVariableObj?.sp_value;
					// this.sp_value = 1879.41;
					this.getIncentiveAndAdditional();
					this.initialiseForm();
					// this.calculateAmounts();
					this.cdr.detectChanges();
				})
			)
			.subscribe();
	}

	initialiseForm() {
		// if (this.amountsControl) {
		// 	const cusInstallerFeeControl = this.amountsControl.get('cus_installer_fee');
		// 	if (cusInstallerFeeControl) {
		// 		cusInstallerFeeControl.setValue(this.sp_value);
		// 	}
		// 	const cusInstallerIncentiveControl = this.amountsControl.get('cus_installer_incentive');
		// 	if (cusInstallerIncentiveControl) {
		// 		cusInstallerIncentiveControl.setValue(this.installer_incentive);
		// 	}
		// 	const cusInstallerAdditionalControl = this.amountsControl.get('cus_installer_additional');
		// 	if (cusInstallerAdditionalControl) {
		// 		cusInstallerAdditionalControl.setValue(this.installer_additional);
		// 	}
		// 	const installerfee = +this.amountsControl.get('cus_installer_fee')?.value;
		// 	const incentive = +this.amountsControl.get('cus_installer_incentive')?.value;
		// 	const additional = +this.amountsControl.get('cus_installer_additional')?.value;
		// 	const deductions = installerfee + incentive + additional;
		// 	this.netresult = this.voucheramount - deductions - this.client_value;
		// 	this.cdr.detectChanges();
		// } 
		if (this.amountsControl) {
			const controls = ['cus_installer_fee', 'cus_installer_incentive', 'cus_installer_additional'];
			const values = {
			cus_installer_fee: this.sp_value,
			cus_installer_incentive: this.installer_incentive,
			cus_installer_additional: this.installer_additional
			};
			
			controls.forEach(controlName => {
			const control = this.amountsControl.get(controlName);
			if (control) control.setValue(values[controlName]);
			});
			
			const installerfee = +this.amountsControl.get('cus_installer_fee')?.value || 0;
			const incentive = +this.amountsControl.get('cus_installer_incentive')?.value || 0;
			const additional = +this.amountsControl.get('cus_installer_additional')?.value || 0;
			const deductions = installerfee + incentive + additional;
			
			this.netresult = this.voucheramount - deductions - this.client_value;
			this.cdr.detectChanges();
			}
		else {
			console.error('amountsControl not found');
		}
	}

	// eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
	ngAfterViewInit() {
		this.calculateAmounts();
		this.cdr.detectChanges();
	}

	getIncentiveAndAdditional() {
		this.incentiveAdditionalSub = this._store
			.select(getFullItemTwo)
			.pipe(
				skipWhile((iaa: any) => !iaa),
				take(1),
				map((result: any) => {
					const { job_information} = result
					if (job_information) {
						const { installer_incentive_amount, additional_fees_amount } = job_information;
					if (installer_incentive_amount) {
						// this.installer_incentive = installer_incentive_amount;
						this.installer_incentive = new DecimalPipe('en-US').transform(Number(installer_incentive_amount), '1.2-2');
					} else {
						this.installer_incentive = new DecimalPipe('en-US').transform(Number(0), '1.2-2');
					}
					if (additional_fees_amount) {
						// this.installer_additional = additional_fees_amount;
						this.installer_additional = new DecimalPipe('en-US').transform(Number(additional_fees_amount), '1.2-2');
					} else {
						this.installer_additional = new DecimalPipe('en-US').transform(Number(0), '1.2-2');
					}
					}
				})
			)
			.subscribe();
	}
	calculateAmounts() {
		this.valueSub = this.bf.bigForm.get('amounts')!.valueChanges.subscribe(value => {
			this.customer_installerfee_deduction = +value.cus_installer_fee;
			this.customer_installerincentive_deduction = +value.cus_installer_incentive;
			this.customer_installeradditional_deduction = +value.cus_installer_additional;
			this.calculateInstaller(this.state);
			this.calculatePaymentDue(this.state);
			this.calculate4SureCut();
		});
	}
	calculate4SureCut() {
		this.netresult =
			this.voucheramount - (this.customer_installerfee_deduction + this.customer_installerincentive_deduction + this.customer_installeradditional_deduction) - this.client_value;
	}
	calculateInstaller(state?: number) {
		if (state === 273) {
			this.installerTotal = this.customer_installerfee_deduction + this.customer_installerincentive_deduction;
		} else if (state === 274) {
			this.installerTotal = this.customer_installerfee_deduction + this.customer_installerincentive_deduction + this.customer_installeradditional_deduction;
		}
		console.log('');
	}
	calculatePaymentDue(state?: number) {
		if (state === 273) {
			this.refund_amount_due = +this.customer_installerfee_deduction + +this.customer_installerincentive_deduction;
		} else if ((state === 274)) {
			this.refund_amount_due = this.customer_installerfee_deduction + this.customer_installerincentive_deduction + this.customer_installeradditional_deduction;
		}
	}
	
	ngOnDestroy() {
		if (this.stateSub) {
			this.stateSub.unsubscribe();
		}
		if (this.valueSub) {
			this.valueSub.unsubscribe();
		}
	}
}
