import { ActionPanelModule } from 'apps/studio/src/app/app-shell-features/action-panel/action-panel.module';
import { HeaderActionsModule } from 'apps/studio/src/app/app-shell-features/header-actions/header-actions.module';
import { AppFooterModule } from 'apps/studio/src/app/app-shell-features/app-footer/app-footer.module';
import { AppBarModule } from 'apps/studio/src/app/app-shell-features/app-bar/app-bar.module';
import { AppMenuModule } from 'apps/studio/src/app/app-shell-features/app-menu/app-menu.module';
import { SearchBannerModule } from 'apps/studio/src/app/app-shell-features/search-banner/search-banner.module';
import { WorkflowAppModule } from 'apps/studio/src/app/application-modules';
import { BucketFilterModule } from 'apps/studio/src/app/plugins/components/bucket-filter/bucket-filter.module';
import { APClaimSummaryModule } from 'apps/studio/src/app/plugins/components/ap-claim-summary/ap-claim-summary.module';
import { JobSummaryModule } from 'apps/studio/src/app/plugins/components/job-summary/job-summary.module';
import { RecentActivityModule } from 'apps/studio/src/app/plugins/components/recent-activity/recent-activity.module';
import { ScratchPadModule } from 'apps/studio/src/app/plugins/components/scratch-pad/scratch-pad.module';
import { SearchModule } from 'apps/studio/src/app/plugins/components/search/search.module';
import { SearchFilterModule } from 'apps/studio/src/app/plugins/components/search-filter/search-filter.module';
import { SPDetailsModule } from 'apps/studio/src/app/plugins/components/sp-details/sp-details.module';
import { APTimeStampsModule } from 'apps/studio/src/app/plugins/components/time-stamps/time-stamps.module';
import { APViewDocumentsModule } from 'apps/studio/src/app/plugins/components/view-documents/view-documents.module';
import { WhatMattersModule } from 'apps/studio/src/app/plugins/components/what-matters/what-matters.module';
import { RemindersPluginModule } from 'apps/studio/src/app/plugins/components/reminders/reminders-plugin.module';
import { TeamMembersModule } from 'apps/studio/src/app/plugins/components/team-members/team-members.module';
export const environment: { [key: string]: any } = {
  client: 'pgg',
  // azure: {
  // 	clientId: '4ab69d74-27cb-49bc-b095-f7bf6332a636',
  // 	loginUrl: 'v1/auth/azure/login',
  // 	codeForTokenExchangeUrl: 'v1/auth/azure/'
  // },
  useMultipleLogins: false,
  production: false,
  branding: {
    logo_url: 'assets/images/PingGo_Logo.svg',
    byline: 'Enterprise Workflow & Supplier Management Platform',
  },
  hmr: false,
  applicationModules: [WorkflowAppModule],
  appShellFeatures: [
    ActionPanelModule,
    AppBarModule,
    HeaderActionsModule,
    AppFooterModule,
    AppMenuModule,
    SearchBannerModule,
  ],
  plugins: {
    'action-panel': [
      APClaimSummaryModule,
      BucketFilterModule,
      JobSummaryModule,
      RecentActivityModule,
      ScratchPadModule,
      SearchModule,
      SearchFilterModule,
      SPDetailsModule,
      APTimeStampsModule,
      APViewDocumentsModule,
      WhatMattersModule,
      RemindersPluginModule,
      TeamMembersModule,
    ],
    global: [RemindersPluginModule],
  },
  api_url: 'https://pinggonp.4-sure.net:10000/api/',
  api_urls: {},
  base_url: 'http://localhost:4200/',
  // websocket_endpoint: 'wss://devchan.4-sure.net:10000/',9
  google_maps_api_key: 'AIzaSyA28JqiZDQ8_CYVbdLKsrA-l_E0iDkW0pw',
  version: '3.0.3',
  dsn: '',

};
