/* eslint-disable @typescript-eslint/no-explicit-any */
import { claimPermissions } from './configs/permissions/claim.permissions';
import { jobPermissions } from './configs/permissions/job.permissions';
import { ClaimWorkflow } from './configs/workflow/claim-workflow';
// import { PggCreateClaim } from './configs/create-claim/pgg-create-claim';
import * as States from './configs/flows';
import { Manifest_0_0_2, getCurrentUser } from '@flexus/core';
import { PggSearch } from './configs/search/pgg-search';
import { applyPggVirtualStates } from './pgg-virtual-state.helper';
import { Store } from '@ngrx/store';
import { PggGenerateExcel } from './configs/generate-excel';
import { map, filter } from 'rxjs/operators';
import { FLXAppMenuComponent } from '../../app-shell-features/app-menu/app-menu.component';

export const pgg: Manifest_0_0_2 = {
	version: '0.0.2',
	orgKey: 'pgg',
	settings: {
		addActionPanel: true,
		addAppBar: true,
		addAppMenu: true,
		addFooter: true,
		addHeaderActions: true,
		addSearchBanner: true,
		addReminder: true
	},
	appMenu: (instance: FLXAppMenuComponent, store: Store<any>) => {
		return store.select(getCurrentUser).pipe(
			filter(user => !!user),
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			map((currentUser: any) => {
				// const createRoles = [1, 6, 18];
				const menuItems = [{ id: 1, name: 'Workflow', routerLink: '/workflow', iconType: 'app-menu-workflow' }];

				// if (currentUser?.user?.roles?.some((role: any) => createRoles.includes(role))) {
				// 	menuItems.push({ id: 2, name: 'New Claim', routerLink: '/workflow/detail', iconType: 'app-menu-new-claim' });
				// }

				return menuItems;
			})
		);
	},
	virtualStatesFunction: applyPggVirtualStates,
	appSearch: PggSearch,
	permissions: {
		itemOne: claimPermissions,
		itemTwo: jobPermissions
	},
	manifestItems: {
		workflow: ClaimWorkflow,
		// create_item_one: PggCreateClaim,
		generate_bulk_excel: PggGenerateExcel,
		// [8 || 172]: SilEditClaim,
		[States.PGG_12.id]: States.PGG_12,
		[States.PGG_14.id]: States.PGG_14,
		[States.PGG_16.id]: States.PGG_16,
		[States.PGG_17.id]: States.PGG_17,
		[States.PGG_20.id]: States.PGG_20,
		[States.PGG_27.id]: States.PGG_27,
		[States.PGG_41.id]: States.PGG_41,
		[States.PGG_42.id]: States.PGG_42,
		[States.PGG_48.id]: States.PGG_48,
		[States.PGG_55.id]: States.PGG_55,
		[States.PGG_62.id]: States.PGG_62,
		[States.PGG_132.id]: States.PGG_132,
		[States.PGG_134.id]: States.PGG_134,
		[States.PGG_140.id]: States.PGG_140,
		[States.PGG_197.id]: States.PGG_197,
		[States.PGG_226.id]: States.PGG_226,
		[States.PGG_229.id]: States.PGG_229,
		[States.PGG_236.id]: States.PGG_236,
		[States.PGG_243.id]: States.PGG_243,
		[States.PGG_258.id]: States.PGG_258,
		[States.PGG_259.id]: States.PGG_259,
		[States.PGG_261.id]: States.PGG_261,
		[States.PGG_263.id]: States.PGG_263,
		[States.PGG_265.id]: States.PGG_265,
		[States.PGG_266.id]: States.PGG_266,
		[States.PGG_272.id]: States.PGG_272,
		[States.PGG_273.id]: States.PGG_273,
		[States.PGG_274.id]: States.PGG_274,
		[States.PGG_284.id]: States.PGG_284,
		[States.PGG_312.id]: States.PGG_312,
		[States.PGG_369.id]: States.PGG_369,
		[States.PGG_371.id]: States.PGG_371,
		[States.PGG_372.id]: States.PGG_372,
		[States.PGG_373.id]: States.PGG_373,
		[States.PGG_375.id]: States.PGG_375,
		[States.PGG_382.id]: States.PGG_382,
	}
};
