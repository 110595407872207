import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, ServerCall_0_0_2, getAllInfo, getFullItemTwo, getSubmissionData } from '@flexus/core';
import { environment } from '../../../../environments/environment';
import gql from 'graphql-tag';
import { forkJoin, of } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';
import { AUTO_SELECT_TEMPLATE_DECISION, SELECT_CALLOUT_TEMPLATE, billingServerCalls } from './BILLING';
import { MakeBillingServerCall } from '@flexus/core';

export const SP_GLOBAL_85: Flow_0_0_2 = {
	id: '85',
	name: 'compile_quote_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Prepare Quote : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Prepare Quote';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: '--'
		},
		viewRoles: {
			0: 'Wait for SP to compile quote'
		}
	},
	serverCalls: {
		IAReports: {
			serviceVariable: 'silService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		claimDetailKeyValues: {
			errorMessage: 'Something went wrong claim details',
			directCall: (http, store, sq, bf) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(itt => !itt),
						take(1),
						map(res => res as any)
					)
					.pipe(
						map(itemTwo => {
							bf.bigForm?.addControl('is_state_85_CIL', new UntypedFormControl(false));
							bf.bigForm?.addControl('must_update_job', new UntypedFormControl(false));

							const claimdetaillist = {
								'Claim Type ': itemTwo?.claim?.type ?? '',
								'Skill required ': itemTwo?.office_use?.skillcatagory ?? '',
								'Address ': itemTwo ? itemTwo?.address : '',
								'Appointment Date ': itemTwo?.office_use?.requestedappointmentdate ?? 'No appointment date set',
								'Appointment Time ': itemTwo?.office_use
                ? `${itemTwo.office_use.appointment_type} ${itemTwo.office_use.requestedappointmenttime}${itemTwo.office_use.requestedappointmentendtime ? ` - ${itemTwo.office_use.requestedappointmentendtime}` : ''}`
                : 'Appointment time not retrieved'
							};
							return [claimdetaillist];
						})
					);
			}
		},
		excessKeyValues: {
			errorMessage: 'Customer contact information could not be retrieved',
			directCall: (http, store, sq, bf) => {
				return forkJoin([
					store.select(getFullItemTwo)?.pipe(
						skipWhile(f => !f),
						take(1),
						map(res => res as any)
					),
					store.select(getAllInfo)?.pipe(
						skipWhile(i => !i),
						take(1),
						map(res => res as any)
					)
				])?.pipe(
					map(([itemTwo, info]) => {
						const job_id = itemTwo?.id;
						const job_excess = itemTwo?.excess;
						let excess_collection: string;
						let excess_amount: string;
						let excess_method: string;
						const excess_whoArr: any = info.excess_who;
						const excess_howArr: any = info.excess_how;
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_amount = job_excess[i]?.amount;
							}
						}
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_whoArr.forEach(element => {
									if (element.id === job_excess[i]?.who_collects) {
										excess_collection = element.name;
									}
								});
							}
						}
						for (let i = 0; i <= job_excess.length - 1; i++) {
							if (job_excess[i]?.job === job_id) {
								excess_howArr.forEach(element => {
									if (element.id === job_excess[i]?.how_collect) {
										excess_method = element.name;
									}
								});
							}
						}
						const excessinfo = {
							'Excess Collection ': excess_collection ? `${excess_collection} to collect` : '-',
							'Excess Amount ': excess_amount ? `R${excess_amount}` : '-',
							'Excess payment ': excess_method,
							'Excess state': 'not set yet'
						};
						return [excessinfo];
					})
				);
			}
		},
		customerContactKeyValues: {
			errorMessage: 'Customer contact information could not be retrieved',
			directCall: (http, store, sq, bf) => {
				return forkJoin([
					store.select(getFullItemTwo)?.pipe(
						skipWhile(f => !f),
						take(1),
						map(res => res as any)
					),
					store.select(getAllInfo)?.pipe(
						skipWhile(i => !i),
						take(1),
						map(res => res as any)
					)
				])?.pipe(
					map(([itemTwo, info]) => {
						console.log(`INFO`, info);
						console.log('Excess', itemTwo?.excess);
						const job_id = itemTwo?.id;

						const customercontactlist = {
							'Client name ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : 'Customer name not available',
							'Client cell ': itemTwo?.claim?.loan_information?.cellnumber ?? 'Cellular number not on record',
							'Customer alternative number ': itemTwo?.claim?.loan_information?.contactnumber ?? 'Contact number not on record'
						};
						return [customercontactlist];
					})
				);
			}
		},
		...billingServerCalls,
		// invoice: billingServerCall('getJobInvoicingStatus', 'payload', 'No invoice could be retrieved', 'invoice'),
		invoice: {
			errorMessage: '',
			directCall: (http, store, sq, bf) => {
				// This is a trick to hide the display of the error message
				const x: any = { displayError: false };
				return of(
					store.dispatch(
						new MakeBillingServerCall({
							functionName: 'retrieveBOQ',
							responseSlice: '',
							errorMessage: 'No invoice could be retrieved',
							dataKey: 'invoice',
							loaderID: 'retrieveBOQ',
							...x
						})
					)
				);
			}
		} as ServerCall_0_0_2
	},
	startNode: 'Summary',
	nodes: {
		Summary: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Compile Quote',
							instructions: ['Please use the screens that follow as reference to compile quote.'],
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Claim Details',
							data$: 'claimDetailKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Excess Details',
							data$: 'excessKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',
							data$: 'customerContactKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
					// {
					//   component: 'FLXKeyValueDisplayWithInstructionsComponent',
					//   inputs: {
					//     title: 'Compile Qoute',
					//     instructions: ['Please use the screens that follow as reference to compile a quote.'],
					//     keyValueList$: 'keyValueListForSummary',
					//     title$: 'title'
					//   }
					// }
				]
			},
			navs: [
				{
					text: 'Change To Cash-In-Lieu',
					nextNode: 'AUTO_SELECT_TEMPLATE_DECISION',
					serverFirst: true,
					color: 'primary',
					location: 'center',
					serverCalls: {
						setAsCIL: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'updateIsCIL'
						}
					}
				},
				{ text: 'Edit Report', nextNode: 'EditReport' },
				{
					text: 'Continue',
					nextNode: 'AUTO_SELECT_TEMPLATE_DECISION'
				}
			]
		},
		AUTO_SELECT_TEMPLATE_DECISION,
		SELECT_CALLOUT_TEMPLATE,
		BILLING_INVOICE: {
			hideTabItem: true,
			component: 'BillingComponent',
			checkValidityForFields: ['invoiceDate', 'invoiceNumber', 'actualLineItemsFormArray'],
			inputs: {
				docType: 'quote',
				numberLabel: 'Quote',
				currentState: 85,
				newState: 27,
				showLineItemGenerator: true,
				canEditLineItems: true,
				boqLogo: environment.client === 'bet_sp' ? 'assets/images/boq-bettersure-logo.svg' : 'assets/images/boq-sil-logo.svg',
				boqLogoAlt: environment.client === 'bet_sp' ? 'Bettersure' : 'Standard Bank Insurance Limited',
				clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL'
			},
			navs: [
				// environment.experimentalFeatures.draftQuoteAndInvoice ? {
				// 	text: 'Save Draft',
				// 	nextNode: 'SubmissionSuccess',
				// 	location: 'right',
				// 	linkType: 'submit',
				// 	optIntoValidation: true,
				// 	serverFirst: true,
				// 	serverCalls: {
				// 		draftQuote: {
				// 			errorMessage: 'An error occurred while trying to create or update BOQ!',
				// 			serviceVariable: 'spService',
				// 			functionName: 'createOrUpdateBOQ',
				// 			followUpSuccessCalls: {
				// 				response: { errorMessage: 'An error occurred when generating quote!', serviceVariable: 'spService', functionName: 'generateDraftBOQQuote' }
				// 			}
				// 		}
				// 	}
				// }: undefined,
				{
					text: 'Generate Quote',
					nextNode: 'SubmissionSuccess',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					disableOnFirstClick: true,
					serverFirst: true,
					serverCalls: {
						postInvoice: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
							}
						}
					}
				}
			]
		},
		EditReport: {
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl('circumstance_of_loss', new UntypedFormControl('', [Validators.required]));
				bf.addControl('description_of_claim', new UntypedFormControl('', [Validators.required]));
				bf.addControl('recommendations', new UntypedFormControl('', [Validators.required]));
				bf.addControl('current_state', new UntypedFormControl(89, [Validators.required]));
				bf.addControl('new_state', new UntypedFormControl(90, [Validators.required]));
				bf.addControl('purpose', new UntypedFormControl('SP Report', [Validators.required]));
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									job_information {
										circumstance_of_loss
										description_of_claim
										recommendations
									}
								}
							}
						}
					`
				)
					.pipe(
						skipWhile(res => !res),
						take(1)
					)
					.subscribe(values => {
						bf.patchValues({
							circumstance_of_loss: values.circumstance_of_loss ? values.circumstance_of_loss : bf.getControl('circumstance_of_loss')?.value || '',
							description_of_claim: values.description_of_claim ? values.description_of_claim : bf.getControl('description_of_claim')?.value || '',
							recommendations: values.recommendations ? values.recommendations : bf.getControl('recommendations')?.value || ''
						});
					});
			},
			serverCalls: {
				dataFile: {
					serviceVariable: '',
					functionName: '',
					errorMessage: 'No file could be found!',
					directCall: (http, store, sq) => {
						return sq
							.queryStore(
								gql`
									{
										selectedContext {
											IAReports
										}
									}
								`
							)
							.pipe(
								skipWhile(result => !result),
								take(1),
								switchMap(result => {
									const allReports = result.IAReports;
									const spReports = [];

									if (allReports) {
										allReports.forEach(report => {
											if (report.purpose === 'SP Report' || report.purpose === 'Internal Assessor Report') {
												spReports.push({ ...report });
											}
										});

										if (spReports) {
											spReports.sort((a, b) => (a.created < b.created ? 1 : -1));

											if (spReports.length > 0) {
												return http
													.post(`${environment.api_url}v1/file_action/get_file/`, {
														file_id: spReports[0]?.id,
														return_type: 1
													})
													.pipe(
														skipWhile(report => !report),
														take(1),
														map((value: any) => {
															return value?.payload;
														})
													);
											}
										}
									}

									//Returning of empty object as when returning empty the loader isn't being removed - tried EMPTY and it does not work either.
									return of({});
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FileViewWithExtraComponent',
						inputs: {
							dataFiles$: 'dataFile'
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									formControlName: 'circumstance_of_loss',
									inputType: 'textarea',
									rows: 3,
									label: 'What caused the loss'
								},
								1: {
									formControlName: 'description_of_claim',
									inputType: 'textarea',
									rows: 3,
									label: 'What is the damage'
								},
								2: {
									formControlName: 'recommendations',
									inputType: 'textarea',
									rows: 3,
									label: 'Recommendations'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw'
						}
					}
				]
			},
			checkValidityForFields: ['circumstance_of_loss', 'description_of_claim', 'recommendations'],
			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							errorMessage: 'Job could not be updated!!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									sq
										.queryObject(
											gql`
												{
													selectedContext {
														fullItemTwo
													}
												}
											`,
											store
										)
										.pipe(
											skipWhile(x => !x),
											take(1),
											map(res => res as any)
										),
									store.select(getSubmissionData)?.pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any[])
									)
								])?.pipe(
									map(([selected, submissiondata]: any[]) => {
										if (selected && submissiondata) {
											const job_id = selected?.fullItemTwo?.id;
											const create_sp_report = 1;
											const circumstance_of_loss = submissiondata?.job_information?.circumstance_of_loss;
											const description_of_claim = submissiondata?.job_information?.description_of_claim;
											const recommendations = submissiondata?.job_information?.recommendations;

											return {
												job_id: job_id,
												create_sp_report: create_sp_report,
												job_information: {
													...selected?.fullItemTwo?.job_information,
													circumstance_of_loss: circumstance_of_loss,
													description_of_claim: description_of_claim,
													recommendations
												},
												purpose: 'SP Report',
												...(selected?.selectedItem?.state === 89 ? { new_state: 97 } : {})
											};
										}
									}),
									switchMap(data => http.post(`${environment.api_url}v1/job_action/update_job/`, data))
								);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
		// TODO: Add other screens, get context from director and discuss with Vaughn
	},
	bigFormToStoreMapper: {
		purpose: 'purpose',
		job_quoted: 'job_information.job_quoted',
		circumstance_of_loss: 'job_information.circumstance_of_loss',
		description_of_claim: 'job_information.description_of_claim',
		recommendations: 'job_information.recommendations',
		current_state: [() => 85, 'current_state'],
		new_state: [() => 83, 'new_state'],
		// total: [
		// 	(a, b, bf, c) => {
		// 		debugger;
		// 		return bf.total + bf.vatRate;
		// 	},
		// 	'job_information.quote_amount'
		// ],
		invoiceNumber: 'job_information.quote_number'
	}
};
