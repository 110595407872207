/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService, Flow_0_0_2, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { CustomValidators } from '@flexus/utilities';
import { setActionPanelItems, CollapseActionPanel } from '../../../../app-shell-features';
import { skipWhile, take, map, switchMap } from 'rxjs/operators';
import { PGG_261 } from '.';
import { environment } from 'apps/studio/src/environments/environment';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import gql from 'graphql-tag';

export const PGG_274: Flow_0_0_2 = {
	id: '274',
	name: 'invoice_increase_request',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['installation-summary', 'documents']),

	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: store => {
			return store.select(getFullItemOne).pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Invoice Increase Request';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		...PGG_261.serverCalls
	},
	instructions: {
		editRoles: {
			0: 'Invoice Increase Request'
		},
		viewRoles: {
			0: 'Invoice Increase Request'
		}
	},
	startNode: 'InvoiceIncreaseRequest',
	nodes: {
		InvoiceIncreaseRequest: {
			name: 'Summary',
			showTabs: true,
			...jobInfoNode(274, 'Invoice Increase Request'),
			navs: [
				{
					text: 'decline',
					color: 'secondary',
					linkType: 'portal',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not update job!',
							directCall: (_http: any, _st: any, _sq: any, _bf: any) => {
								_bf.patchValues({ new_state: 26 });
								return _st
									.select(getFullItemTwo)
									.pipe(
										skipWhile((selected: any) => !selected),
										take(1),
										map((res: any) => res)
									)
									.pipe(
										switchMap((selectedjob: any) => {
											const job_id = selectedjob?.id;
											const state = _bf.getControl('new_state')?.value;
											const request = {
												job_id: job_id,
												new_state: state
											};
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
										})
									);
							}
						}
					}
				},
				{
					text: 'approve',
					color: 'primary',
					// optIntoValidation: true,
					linkType: 'portal',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not update job!',
							directCall: (_http: HttpClient, _store: Store, _sq: any) => {
								return _sq
									.queryObject(
										gql`
											{
												fullItemTwo {
													job_information
													id
												}
											}
										`,
										_store.select(getFullItemTwo).pipe(
											skipWhile((fj: any) => !fj),
											take(1),
											map((result: any) => ({ fullItemTwo: result }))
										)
									)
									.pipe(
										switchMap((query_data: any) => {
											const { job_information, id } = query_data;
											const additional_amount = +job_information?.additional_fees_amount;
											const reason = job_information?.additional_fees_reason;
											const request = {
												job_id: id,
												additional_amount: additional_amount,
												reason: reason,
												force: true,
												new_state: 26
											};
											return _http.post(`${environment.api_url}v1/job_action/add_additional_cost_to_job/`, request);
										})
									);
							}
						}
					}
				}
			]
		},
		PaymentDetails: {
			initFormFields: (_bf: any, item: any, _inst: any, _sq: any, _store: Store) => {
				_store
					.select(getFullItemTwo)
					.pipe(
						skipWhile(cb => !cb),
						take(1)
					)
					.subscribe((result: any) => {
						const values = result?.claim?.loan_information?.values;
						const jobinfo = result?.job_information;
						const incentive = +jobinfo?.installer_incentive_amount;
						const additional = +jobinfo?.additional_fees_amount;
						
						const client_value = values?.client_value;
						const sp_value = values?.sp_value;
						_bf.addControl(
							'amounts',
							new UntypedFormGroup({
								cus_takealot_fee: new UntypedFormControl(client_value, [CustomValidators.numeric]),
								cus_installer_fee: new UntypedFormControl(sp_value, []),
								cus_installer_incentive: new UntypedFormControl(incentive, [CustomValidators.numeric]),
								cus_installer_additional: new UntypedFormControl(additional, [CustomValidators.currency, Validators.required]),
								cus_sure_fee: new UntypedFormControl(0, [])
							})
						);
					});
				_bf.addControl('new_state', new UntypedFormControl(null, []));
				_bf.addControl('incentive_amount', new UntypedFormControl(null, []));
				_bf.addControl('additional_amount', new UntypedFormControl(null, []));
				_bf.addControl('claim_value', new UntypedFormControl(null, []));
			},
			checkValidityForFields: ['cus_installer_additional'],
			showTabs: true,
			name: 'Payment Details',
			serverCalls: {
				...PGG_261?.nodes?.RefundQueryCalculator?.serverCalls
			},
			component: {
				children: [
					{
						component: 'PinggoSPPaymentBreakdownComponent',
						inputs: {
							paymentVariables$: 'refundVariablesFromSource',
							state: 274
						}
					}
				]
			},
			navs: [
				{
					text: 'decline',
					color: 'secondary',
					linkType: 'portal',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not update job!',
							directCall: (_http: any, _st: any, _sq: any, _bf: any) => {
								_bf.patchValues({ new_state: 26 });
								return _st
									.select(getFullItemTwo)
									.pipe(
										skipWhile((selected: any) => !selected),
										take(1),
										map((res: any) => res)
									)
									.pipe(
										switchMap((selectedjob: any) => {
											const job_id = selectedjob?.id;
											const state = _bf.getControl('new_state')?.value;
											const request = {
												job_id: job_id,
												new_state: state
											};
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
										})
									);
							}
						}
					}
				},
				{
					text: 'approve',
					// optIntoValidation: true,
					color: 'primary',
					linkType: 'portal',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not update job!',
							directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
								return _sq
									.queryObject(
										gql`
											{
												fullItemTwo {
													job_information
													id
												}
											}
										`,
										_store.select(getFullItemTwo).pipe(
											skipWhile((fj: any) => !fj),
											take(1),
											map((result: any) => ({ fullItemTwo: result }))
										)
									)
									.pipe(
										switchMap((query_data: any) => {
											const { job_information, id } = query_data;
											const additional_amount = +_bf.getControl('amounts.cus_installer_additional').value;

											const reason = job_information?.additional_fees_reason;
											const request = {
												job_id: id,
												additional_amount: additional_amount,
												reason: reason,
												force: true,
												new_state: 26
											};
											return _http.post(`${environment.api_url}v1/job_action/add_additional_cost_to_job/`, request);
										})
									);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		cus_installer_additional: [
			(inc_amount: any) => {
				return inc_amount;
			},
			'job_information.additional_fees_amount'
		]
	}
};
