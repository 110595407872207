<div class="container">
	<form *ngIf="bf.bigForm" [formGroup]="bf.bigForm">
		<div class="refund-calculator" formGroupName="amounts">
			<div class="heading"><flx-heading size="medium" color="default" align="center">Payment Details</flx-heading></div>
			<div *ngIf="subheading">
				<flx-heading size="extra-small" weight="light" color="secondary" align="center">{{ subheading }}</flx-heading>
			</div>

			<!-- <div class="calculator-screen"> -->
			<div class="calculator-screen-minimis">
				<div class="variable-heading-row">
					<div class="spacer"></div>
					<div class="screen-heading">Job Profitability</div>
				</div>
				<flx-glow-line></flx-glow-line>

				<div class="dynamic-breakdown-columns">
					<div class="descriptors">
						<div class="descriptor">Approved SP Job Fee</div>
						<div class="descriptor">Pre-job SP Extras Fee</div>
						<div *ngIf="state === 274" class="descriptor">Post-job SP Extras Fee</div>
					</div>

					<div class="customer-refund-breakdown">
						<div class="i-breakdown-panel">
							<div class="currency">R</div>
							<div class="input-text" style="text-align: right">
								<flx-input-text formControlName="cus_installer_fee" textAlign="right" [readonly]="true" style="padding-right: 17px;"></flx-input-text>
							</div>
						</div>
						<div class="i-breakdown-panel">
							<div class="currency">R</div>
							<div class="input-text">
								<flx-input-text
									formControlName="cus_installer_incentive"
									textAlign="right"
									type="decimal"
									textAlign="right"
									width="95px"
									[disabled]="state === 274"
								></flx-input-text>
							</div>
						</div>
						<div *ngIf="state === 274" class="i-breakdown-panel">
							<div class="currency">R</div>
							<div class="input-text">
								<flx-input-text formControlName="cus_installer_additional" type="currency" width="95px" textAlign="right" [disabled]="state === 273"></flx-input-text>
							</div>
						</div>
						<!-- <div class="i-breakdown-panel">
							<div class="currency">-R</div>
							<div class="input-text"><flx-input-text formControlName="cus_sure_fee" type="decimal" width="85px"></flx-input-text></div>
							<div class="icon"><flx-icon [type]="'remove'"></flx-icon></div>
						</div> -->
					</div>
				</div>
				<flx-reactive-form-error-display [control]="$any(bf).bigForm.controls['amounts']?.value.cus_installer_additional">hello </flx-reactive-form-error-display>
				<div class="bottom-glow-line"><flx-glow-line></flx-glow-line></div>
				<div class="total-summary">
					<div class="dynamic-summary-columns">
						<div class="spacer"></div>

						<div class="refund-due">
							<div class="r-breakdown-panel">
								<div class="summary-descriptor boldprimary">Approved SP Cost</div>
								<div class="summary-amount boldprimary">{{ refund_amount_due | currency: 'R' }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="voucher-amount-row">
						<div style="font-weight: 400">4-Sure Revenue</div>
						<div style="font-weight: 400">{{ voucheramount | currency: 'R' }}</div>
					</div>
					<div class="voucher-amount-row">
						<div style="font-weight: 400">less: Partner Margin</div>
						<div style="font-weight: 400">{{ client_value | currency: 'R' }}</div>
					</div>
					<div class="voucher-amount-row">
						<div style="font-weight: 400">less: Approved SP Cost</div>
						<div style="font-weight: 400">{{ installerTotal | currency: 'R' }}</div>
					</div>
					<div style="margin-top: 10px"><flx-glow-line></flx-glow-line></div>
					<div class="voucher-amount-row">
						<div style="font-weight: 600; margin-top: 10px">4-Sure Profit/Loss</div>
						<div style="font-weight: 600; margin-top: 10px">{{ netresult | currency: 'R' }}</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<!--

  <div class="sure-net-result">
						<div class="r-breakdown-panel">
							<div class="summary-descriptor">4-Sure Net Result</div>
							<div class="summary-amount">{{ netresult | currency: 'R' }}</div>
						</div>
					</div>
-->
