/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigFormService, Flow_0_0_2, MakeServerCall, ManifestController, ModalService, SetNextNode, getFullItemOne, getFullItemTwo} from '@flexus/core';
import { setActionPanelItems } from '../../../../app-shell-features';
import { skipWhile, take, map, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'apps/studio/src/environments/environment';
import { UntypedFormControl, Validators } from '@angular/forms';
import { addObjectProperty, CustomValidators } from '@flexus/utilities';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';

export const PGG_373: Flow_0_0_2 = {
	id: '373',
	name: 'hardware_warranty_request',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Hardware Warranty Request';
					if (itemOne?.loan_information?.voucher_key) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Decide Warranty Outcome'
		},
		viewRoles: {
			0: 'Warranty Outcome Decision'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'pggService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		
	},
	startNode: 'jobInformation',
	nodes: {
		jobInformation: {
            initFormFields: (_bf: BigFormService) => {
                _bf.addControl('new_state', new UntypedFormControl( null, [Validators.required]));
            },
			showTabs: false,
			...jobInfoNode(373),
           
            navs: [
                { 
                    text: 'no additional work',
                    color: 'primary',
                    nextNode: 'submissionSuccess',
                    serverCalls: {
                        updateJob: {
                            errorMessage:'could not update job',
                            directCall: (_http: HttpClient, _store: Store, _sq, _bf: BigFormService) => {
                                _bf.patchValues({new_state: 375});
                                _bf.addControl('warranty_job_decision', new UntypedFormControl('NO_ADDITIONAL_WORK_REQUIRED', [Validators.required]));
                                _store.select(getFullItemTwo).pipe(
                                    skipWhile((x: any) => !x),
                                    take(1),
                                    map((res: any) => res),
                                    switchMap((res: any) => {
                                        const job_id = res.id;
                                        const { job_information } = res;
                                        const job_information_json = JSON.parse(JSON.stringify(job_information))
                                        const mod_jobinfo = addObjectProperty('warranty_job_decision',_bf.getControl('warranty_job_decision').value, job_information_json)	
                                        const request_object = {
                                            job_id: job_id,
                                            new_state: _bf.getControl('new_state').value,
                                            job_information: mod_jobinfo
                                        }
                                        return _http.post(`${environment.api_url}v1/job_action/update_job/`, request_object);
                                    })
                                ).subscribe()



                                return of({})
                            }
                        }
                    }
                },
                { 
                    text: 'customer to deal with manufacturer',
                    color: 'primary',
                    // linkType: 'submit',
                    nextNode: 'submissionSuccess',
                    serverCalls: {
                        updateJob: {
                            errorMessage:'could not update job',
                            directCall: (_http: HttpClient, _store: Store, _sq, _bf: BigFormService) => {
                                _bf.patchValues({new_state: 375});
                                _bf.addControl('warranty_job_decision', new UntypedFormControl('CUSTOMER_TO_DEAL_WITH_MANUFACTURER', [Validators.required]));
                                _store.select(getFullItemTwo).pipe(
                                    skipWhile((x: any) => !x),
                                    take(1),
                                    map((res: any) => res),
                                    switchMap((res: any) => {
                                        const job_id = res.id;
                                        const { job_information } = res;
                                        const job_information_json = JSON.parse(JSON.stringify(job_information))
                                        const mod_jobinfo = addObjectProperty('warranty_job_decision', _bf.getControl('warranty_job_decision').value, job_information_json)	
                                        const request_object = {
                                            job_id: job_id,
                                            new_state: _bf.getControl('new_state').value,
                                            job_information: mod_jobinfo
                                        }
                                        return _http.post(`${environment.api_url}v1/job_action/update_job/`, request_object);
                                    })
                                ).subscribe()



                                return of({})
                            }
                        }
                    }
                },
                { 
                    text: 'builders requires assistance',
                    color: 'primary',
                    nextNode: 'BuildersAssistanceRequired',
                  
                },
                { 
                    text: 'manufacturer needs help',
                    color: 'primary',
                    nextNode: 'ManufacturerAssistanceRequired'
                },
                
            ]
		},
        BuildersAssistanceRequired: {
            initFormFields: (_f: BigFormService) => {
                	_f.addControl('builders_sp_assistance', new UntypedFormControl(null, [Validators.required]));
            },
            checkValidityForFields: ['builders_sp_assistance'],
            component: 'FLXFlatDynamicFormComponent',
            inputs: {
                heading: 'What assistance is required?',
                formControls: {
                    0: {
                        label: 'What assistance is required from the service provider?',
                        inputType: 'textarea',
                        placeholder: '',
                        rows: 5,
                        formControlName: 'builders_sp_assistance'
                    }
                },
                formLayout: 'stacked',
                containerWidth: '30vw',
                headingSize: 'medium',
                headingWeight: 'normal',
                headingType: 'creation',
                headingMargin: '20px 0 25px 0'
            },
            navs: [
                {
                    text: 'submit',
                    color: 'primary',
                    linkType: 'submit',
                    optIntoValidation: true,
                    serverCalls: {
                        submission_modal: {
                            errorMessage: 'could not update job to state 374',
                            directCall: (_h,_store, _sq, _bf: BigFormService, cntrl, _modal: ModalService) => {
                                let sp_touse
                                _bf.patchValues({new_state: 374});
                                _store.select(getFullItemTwo).pipe(
                                    skipWhile((x: any) => !x),
                                    take(1),
                                    map((res: any) => {
                                       const {team_leader} = res
                                       const {sp} = team_leader
                                       sp_touse = sp
                                    }),
                                ).subscribe()
                                _modal.openModalDirectly(inst => {
                                    inst.type = 'warning';
                                    inst.heading = 'Please inform the SP that further work is required';
                                    inst.setMessage([`SP Name ${sp_touse?.name}`, `SP Contact Number ${sp_touse?.contact_primary}`]);
                                    inst.navButtons = [
                                        {
                                            text: 'i have informed the sp',
                                            color: 'default',
                                            linkType: 'close',
                                            clickHandler: () => {
                                                _store.dispatch(
                                                    new MakeServerCall({
                                                        errorMessage: 'could not update job to state 374',
                                                        directCall: (_http: HttpClient, _store: Store, _sq, _bf: BigFormService, _cntrllr: ManifestController<any>) => {
                                                            _bf.patchValues({new_state: 374});
                                                            _bf.addControl('warranty_job_decision', new UntypedFormControl('BUILDERS_REQUIRES_ASSISTANCE', [Validators.required]));
                                                            _store.select(getFullItemTwo).pipe(
                                                                skipWhile((x: any) => !x),
                                                                take(1),
                                                                map((res: any) => res),
                                                                switchMap((res: any) => {
                                                                    const job_id = res.id;
                                                                    const { job_information } = res;
                                                                    const job_information_json = JSON.parse(JSON.stringify(job_information))
                                                                    const mod_jobinfo1 = addObjectProperty('warranty_job_decision', _bf.getControl('warranty_job_decision').value, job_information_json)	
                                                                    const job_info_json2 = JSON.parse(JSON.stringify(mod_jobinfo1))
                                                                    const mod_jobinfo2 = addObjectProperty('builders_sp_assistance', _bf.getControl('builders_sp_assistance').value, job_info_json2)
                                                                    const request_object = {
                                                                        job_id: job_id,
                                                                        new_state: _bf.getControl('new_state').value,
                                                                        job_information: mod_jobinfo2
                                                                    }
                                                                    return _http.post(`${environment.api_url}v1/job_action/update_job/`, request_object);
                                                                })
                                                            ).subscribe()
                                                            _cntrllr.dispatch(new SetNextNode('submissionSuccess'));

                                                            return of({})
                                                        }
                                                    })
                                                )
                                            }
                                            
                                        }
                                    ]
                                })
                                return of({})
                            }
                        }
                    }

                }
            ]
        },
        ManufacturerAssistanceRequired: {
            initFormFields: (_f: BigFormService) => {
                _f.addControl('manuf_company_name', new UntypedFormControl(null, [Validators.required,CustomValidators.alphaNumericWithSpaces ]));
                _f.addControl('manuf_contact_person', new UntypedFormControl(null, [Validators.required, CustomValidators.alphaNumericWithSpaces]));
                _f.addControl('manuf_contact_person_phone', new UntypedFormControl(null, [Validators.required, CustomValidators.cellphone]));
                _f.addControl('manuf_contact_person_email', new UntypedFormControl(null, [Validators.required, CustomValidators.email]));
                _f.addControl('manufacturer_sp_assistance', new UntypedFormControl(null, [Validators.required]));
        },
        checkValidityForFields: ['manuf_company_name','manuf_contact_person','manuf_contact_person_phone','manuf_contact_person_email','manufacturer_sp_assistance'],
       component: {
        children: [
            {
                component: 'FLXHeadingWithInstructionsComponent',
                inputs: {
                    title: 'Manufacturer Details',
                    headingConfig: {
                        size: 'medium',
                        weight: 'light',
                        itemMargin: '25px 0 8px 0'
                    }
                }
            },
            {
                component: 'FLXHeadingWithInstructionsComponent',
                inputs: {
                    title: 'Please record the details',
                    headingConfig: {
                        color: 'secondary',
                        size: 'small',
                        weight: 'normal',
                        itemMargin: '0 0 10px 0'
                    }
                }
            },
           {
            component: 'FLXFlatDynamicFormComponent',
            inputs: {
                // heading: 'What assistance is required?',
                formControls: {
                    0: {
                        label: 'Company Name',
                        inputType: 'input',
                        formControlName: 'manuf_company_name'
                    },
                    1: {
                        label: 'Contact Person Name',
                        inputType: 'input',
                        formControlName: 'manuf_contact_person'
                    },
                    2: {
                        label: 'Contact Person Phone Number',
                        inputType: 'input',
                        formControlName: 'manuf_contact_person_phone'
                    },
                    3: {
                        label: 'Contact Person Email',
                        inputType: 'input',
                        formControlName: 'manuf_contact_person_email'
                    },
                    4: {
                        label: 'What assistance is required from the service provider?',
                        inputType: 'textarea',
                        placeholder: '',
                        rows: 5,
                        formControlName: 'manufacturer_sp_assistance'
                    }
                },
                formLayout: 'stacked',
                containerWidth: '25vw',
                headingSize: 'medium',
                headingWeight: 'normal',
                headingType: 'creation',
                headingMargin: '20px 0 25px 0'
            },
           } 
        ]
       },
        navs: [
            {
                text: 'submit',
                color: 'primary',
                linkType: 'submit',
                optIntoValidation: true,
                serverCalls: {
                    submission_modal: {
                        errorMessage: 'could not update job to state 374',
                        directCall: (_h,_store, _sq, _bf: BigFormService, cntrl, _modal: ModalService) => {
                            let sp_touse
                            _bf.addControl('warranty_job_decision', new UntypedFormControl('MANUFACTURER_REQUIRES_ASSISTANCE', [Validators.required]));
                            _bf.patchValues({new_state: 374});
                            _store.select(getFullItemTwo).pipe(
                                skipWhile((x: any) => !x),
                                take(1),
                                map((res: any) => {
                                   const {team_leader} = res
                                   const {sp} = team_leader
                                   sp_touse = sp
                                }),
                            ).subscribe()
                            _modal.openModalDirectly(inst => {
                                inst.type = 'warning';
                                inst.heading = 'Please inform the SP that further work is required';
                                inst.setMessage([`SP Name ${sp_touse?.name}`, `SP Contact Number ${sp_touse?.contact_primary}`]);
                                inst.navButtons = [
                                    {
                                        text: 'i have informed the sp',
                                        color: 'default',
                                        linkType: 'close',
                                        clickHandler: () => {
                                            _store.dispatch(
                                                new MakeServerCall({
                                                    errorMessage: 'could not update job to state 374',
                                                    directCall: (_http: HttpClient, _store: Store, _sq, _bf: BigFormService, _cntrllr: ManifestController<any>) => {
                                                        _bf.patchValues({new_state: 374});
                                                        _store.select(getFullItemTwo).pipe(
                                                            skipWhile((x: any) => !x),
                                                            take(1),
                                                            map((res: any) => res),
                                                            switchMap((res: any) => {
                                                                const job_id = res.id;
                                                                const { job_information } = res;
                                                                const manufacturer_aid = true;
                                                                const manufacturer_sp_assistanceObj = {
                                                                    manuf_company_name: _bf.getControl('manuf_company_name').value,
                                                                    manuf_contact_person: _bf.getControl('manuf_contact_person').value,
                                                                    manuf_contact_person_phone: _bf.getControl('manuf_contact_person_phone').value,
                                                                    manuf_contact_person_email: _bf.getControl('manuf_contact_person_email').value,
                                                                    manufacturer_sp_assistance: _bf.getControl('manufacturer_sp_assistance').value
                                                                }
                                                                const job_information_json = JSON.parse(JSON.stringify(job_information))
                                                                const mod_jobinfo1 = addObjectProperty('warranty_job_decision', _bf.getControl('warranty_job_decision').value, job_information_json)	
                                                                const job_info_json2 = JSON.parse(JSON.stringify(mod_jobinfo1))
                                                                const mod_jobinfo2 = addObjectProperty('manufacturer_sp_assistance', manufacturer_sp_assistanceObj, job_info_json2)
                                                                const job_info_json3 = JSON.parse(JSON.stringify(mod_jobinfo2))
                                                                const mod_jobinfo3 = addObjectProperty('manufacturer_aid', manufacturer_aid, job_info_json3)
                                                                const request_object = {
                                                                    job_id: job_id,
                                                                    new_state: _bf.getControl('new_state').value,
                                                                    job_information: mod_jobinfo3
                                                                }
                                                                return _http.post(`${environment.api_url}v1/job_action/update_job/`, request_object);
                                                            })
                                                        ).subscribe()
                                                        _cntrllr.dispatch(new SetNextNode('submissionSuccess'));
                                                        return of({})
                                                    }
                                                })
                                            )
                                        },
                                        nextNode: 'submissionSuccess'
                                    }
                                ]
                            })
                            return of({})
                        }
                    }
                }

            }
        ]
        },
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
        service_provider_assistance: 'job_information.service_provider_assistance'
	}
};
